import { render, staticRenderFns } from "./index.vue?vue&type=template&id=5d0f94e8&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=5d0f94e8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d0f94e8",
  null
  
)

export default component.exports
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTabPanel,QTab});
