<template lang="pug">
.tab-cat
  LoadingSpinner(v-if="loading")
  .tab-cat__cats(v-else-if="!selectedCat")
    CatItem(v-for="cat in catList" :cat="cat" :key="cat.id" @click.native="selectCat(cat)")
  .tab-cat__selected-cat(v-else)
    CatItem(:cat="selectedCat")
    BackBtn(:type="1" @go-back="resetCats").q-mt-md Выбрать другую категорию
    .tab-cat__counter
      .weight-medium Ваши последние результаты
      span(style="color: #7C74E9") •
      span {{biomarkerQuantity}}
    .tab-cat__analyzes
      template(v-for="analyze in catResults" )
        AnalyzeItem(
          :key="analyze.id"
          v-if="analyze.is_detailed_page"
          :analyze="analyze"
          type="link")
</template>

<script>
import { getCategories, getCategoriesResults } from '@/api/analyzes.js';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import CatItem from './CatItem.vue';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import { pluralizeText } from '@/utils/utils';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';


export default {
  components: {LoadingSpinner, CatItem, AnalyzeItem, BackBtn},
  data(){
    return {
      loading: false,
      catList: [],
      selectedCat: null,
      catResults: [],
      biomarkersCount: null
    }
  },
  async mounted() {
    this.loading = true;
    this.catList = await getCategories()
    this.loading = false;
  },
  methods: {
    async selectCat(cat) {
      this.loading = true
      this.selectedCat = cat
      const res = await getCategoriesResults({category_id: cat.id})
      this.catResults = res.results
      this.biomarkersCount = res.count
      this.loading = false
    },
    resetCats() {
      this.selectedCat = null
      this.catResults = []
    }
  },
  computed: {
    biomarkerQuantity() {
      const ru = ['биомаркер', 'биомаркера', 'биомаркеров'];
      return `${this.biomarkersCount} ${pluralizeText(this.biomarkersCount, ru)}`;
    }
  }
}
</script>

<style lang="scss">
  .tab-cat__cats {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 12px;

    @include media-down($breakpoint-lg) {
      grid-template-columns: repeat(4, 1fr);
    }
    @include media-down($breakpoint-md) {
      grid-template-columns: repeat(3, 1fr);
    }
    @include media-down($breakpoint-sm) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-down($breakpoint-xs) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .cat-item {
    max-width: 240px;
    height: 130px;
    background: $light-white;
    box-shadow: 0px 4px 15px $shadow-color;
    border-radius: 25px;
    padding: 25px;

    display: flex;
    justify-content: space-between;
    cursor: pointer;

    @include media-down($breakpoint-xs) {
      max-width: 100%;
    }
  }

  .cat-item__title {
    max-width: 125px;
    color: $accent-color;
  }

  .cat-item__group {
    color: $grey-3
  }

  .cat-item__image {
    width: 50px;
    height: 50px;
    align-self: flex-end;
  }

  .tab-cat__analyzes {
    display: flex;
    flex-direction: column;
    gap: 6px
  }

  .tab-cat__counter {
    margin: 50px 0 24px;
    display: flex;
    gap: 10px;
  }
</style>