import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import Tabs from '@/components/Tabs.vue';
import BaseTabs from '@/components/BaseTabs.vue';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import { bus } from '@/plugins/bus';
import EmptyAnalyzes from '@/views/AnalyzesPage/EmptyAnalyzes.vue';
import TabAll from '@/views/AnalyzesPage/TabAll.vue';
import TabCat from '@/views/AnalyzesPage/TabCat.vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
let AnalyzesPage = class AnalyzesPage extends Vue {
    constructor() {
        super(...arguments);
        this.activeTab = IAnalyzes.TabsName.ALL_ANALYZES;
        this.tabsData = [
            {
                name: IAnalyzes.TabsName.ALL_ANALYZES,
                label: 'Все анализы',
                componentName: TabAll,
            },
            {
                name: IAnalyzes.TabsName.BY_CATEGORY,
                label: 'По категориям',
                componentName: TabCat,
            },
        ];
        this.loading = false;
    }
    async mounted() {
        // TODO выпилить лишние запросы
        this.loading = true;
        await Promise.all([
            this.$store.dispatch('analyzes/analyzeRubrics'),
            this.$store.dispatch('analyzes/setCheckBoxValues'),
            this.$store.dispatch('analyzes/analyzeResultsList'),
        ]);
        this.loading = false;
    }
    onTabChange(value) {
        this.activeTab = value;
    }
    goToHistoryPage() {
        this.$router.push({ name: ROUTE_NAME.ORDER_HISTORY });
    }
    destroyed() {
        bus.$off(IAnalyzes.BusEvents.SHOW_FILTER);
    }
};
AnalyzesPage = __decorate([
    Component({
        components: {
            Tabs,
            MainBtn,
            EmptyAnalyzes,
            BaseTabs,
            TabAll,
            TabCat,
            LoadingSpinner
        },
    })
], AnalyzesPage);
export default AnalyzesPage;
