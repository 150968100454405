<template>
  <div class="empty-analyzes">
    <img src="@/assets/flask.svg" alt="flask">
    <div class="empty-analyzes__text">
      <div class="h2">Пока здесь ничего нет</div>
      <div class="empty-analyzes__descr">
        Сфотографируйте или загрузите документ с результатами любого анализа, или же добавьте анализ вручную
      </div>
    </div>
    <MainBtn
      type="small-bg"
      text="Добавить анализ"
      @click.native="openResearchModal"> 
      <template #icon>
        <icon name="add-icon" width="10px" />
      </template>
    </MainBtn>
  </div>
</template>
  
<script>
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';

export default {
  components: {MainBtn},
  methods: {
      openResearchModal() {
      bus.$emit(IAnalyzes.BusEvents.ADD_RESEARCH_OPEN);
    }
  }
}

</script>

<style lang="scss">
  .empty-analyzes {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 580px;
    gap: 28px;
    border-radius: 40px;
    @include border-dashed(2, 5, 'D4D1FD', 40);
  }
  .empty-analyzes__descr {
    color: #A0A0B8;
    font-size: 14px;
    line-height: 20px;
  }
  .empty-analyzes__text {
    max-width: 335px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 7px;
    @include media-down($breakpoint-md) {
      padding: 0 20px;
    }
  }
</style>
