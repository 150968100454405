<template lang="pug">
SearchInput(v-model="query" :focus="!!query" @blur="query = ''" placeholder="Поиск" @input="$emit('input', $event)").searchAnalyzes
  //- template(v-if="query" #append): icon(name="close-icon")
</template>

<script>
import SearchInput from '@/components/UI/inputs/SearchInput.vue';
export default {
  components: { SearchInput },
  data() {
    return {
      query: ''
    }
  }
}
</script>

<style lang="scss">
.searchAnalyzes {
  align-self: flex-start;
  padding-top: 30px;
  width: 200px;
  transition: 0.3s;

  .q-field__control {
    border: 1px solid $grey-5;
    background: transparent !important;
    height: 50px;
    box-shadow: none !important;
  }

  .q-field__prepend,
  .q-field__append {
    height: 50px;
  }

  &.q-field--focused {
    @include media-up($breakpoint-md) {
      width: 300px;
    }
    .q-field__control {
      background: white !important;
    }
  }

  @include media-down($breakpoint-md) {
    width: 100%;
  }
}
</style>