import { render, staticRenderFns } from "./SortSelect.vue?vue&type=template&id=dff26c1a&scoped=true&"
import script from "./SortSelect.vue?vue&type=script&lang=ts&"
export * from "./SortSelect.vue?vue&type=script&lang=ts&"
import style0 from "./SortSelect.vue?vue&type=style&index=0&id=dff26c1a&lang=scss&scoped=true&"
import style1 from "./SortSelect.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dff26c1a",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QField from 'quasar/src/components/field/QField.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QItem,QItemSection,QItemLabel,QField,QMenu,QVirtualScroll});
