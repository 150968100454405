import { __decorate } from "tslib";
import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import SortSelect from '@/components/UI/SortSelect.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import { bus } from '@/plugins/bus';
import AnalyzesSelect from '@/components/analyzes/AnalyzesSelect.vue';
let AnalyzesByCategoryActions = class AnalyzesByCategoryActions extends Vue {
    constructor() {
        super(...arguments);
        this.checkboxState = {};
        this.isResetButtonShow = false;
        // TODO
        this.isDesktop = window.screen.width >= 767;
        this.selectOptionList = [
            { value: '-date', label: 'По дате по убыванию' },
            { value: 'date', label: 'По дате по возрастанию' },
            { value: 'name', label: 'По названию от А-Я' },
            { value: '-name', label: 'По названию Я-А' },
            { value: 'out_of_norm_first', label: 'Сначала не в норме' },
            { value: 'in_norm_first', label: 'Сначала в норме' },
            { value: 'out_of_norm_only', label: 'Только не в норме' },
            { value: 'in_norm_only', label: 'Только в норме' },
        ];
        this.sortedValue = this.selectOptionList[0];
    }
    mounted() {
        const sortType = this.$route.query.sort || '-date';
        this.sortedValue = this.selectOptionList.find(item => item.value === sortType);
    }
    destoyed() {
        this.resetFilters();
    }
    sortAnalyzes($event) {
        this.sortedValue = $event;
        this.$emit('sort', $event.value);
    }
    get isCompareMode() {
        return this.$store.state.analyzes.compareMode;
    }
    get checkBoxValues() {
        this.checkboxState = { ...this.$store.state.analyzes.checkBoxValues };
        return this.$store.state.analyzes.checkBoxValues;
    }
    get analyzeResultsList() {
        return this.$store.state.analyzes.analyzeResultsList;
    }
    get defaultAnalyzeResultsList() {
        return this.$store.state.analyzes.defaultAnalyzeResultsList;
    }
    get checkedArr() {
        return this.$store.getters['analyzes/checkedArr'];
    }
    onCheckChange(key) {
        this.checkboxState[key] = !this.checkboxState[key];
    }
    showFilters(payload) {
        bus.$emit(IAnalyzes.BusEvents.SHOW_FILTER, payload);
    }
    onSelect(e) {
        this.isResetButtonShow = true;
        return e;
        // bus.$emit(IAnalyzes.BusEvents.SET_CATEGORY, Boolean(this.checkedArr));
    }
    setCompareMode() {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'compareMode', value: !this.isCompareMode });
    }
    resetFilters() {
        const items = this.checkboxState;
        for (const key in items) {
            items[key] = false;
        }
        this.$store.commit('analyzes/setPropertyInStore', { name: 'checkBoxValues', value: items });
        this.$store.commit('analyzes/setPropertyInStore', { name: 'selectedRubricIds', value: [] });
        this.onSelect();
        this.isResetButtonShow = false;
    }
};
__decorate([
    Prop()
], AnalyzesByCategoryActions.prototype, "mobileSearchMode", void 0);
__decorate([
    Emit('setFilter')
], AnalyzesByCategoryActions.prototype, "onSelect", null);
AnalyzesByCategoryActions = __decorate([
    Component({
        components: {
            SortSelect,
            CheckboxInput,
            MainBtn,
            AnalyzesSelect,
        },
    })
], AnalyzesByCategoryActions);
export default AnalyzesByCategoryActions;
