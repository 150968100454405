import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import InputDate from '@/components/InputDate.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import MainSelect from '@/components/UI/MainSelect.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
;
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import BackBtn from '@/components/UI/buttons/BackBtn.vue';
import { bus } from '@/plugins/bus';
let AnalyzesFilter = class AnalyzesFilter extends Vue {
    constructor() {
        super(...arguments);
        this.sortedValue = null;
        this.selectOptionList = [
            'По дате загрузки по убыванию',
            'По дате загрузки по возрастанию',
            'По дате исследования по убыванию',
            'По дате исследования по возрастанию',
            'По типу исследования',
            'Сначала расшифрованные',
            'Сначала нерасшифрованные',
        ];
        this.selectOptionList2 = [
            { value: '-date', label: 'По дате по убыванию' },
            { value: 'date', label: 'По дате по возрастанию' },
            { value: 'name', label: 'По названию от А-Я' },
            { value: '-name', label: 'По названию Я-А' },
            { value: 'out_of_norm_first', label: 'Сначала не в норме' },
            { value: 'in_norm_first', label: 'Сначала в норме' },
            { value: 'out_of_norm_only', label: 'Только не в норме' },
            { value: 'in_norm_only', label: 'Только в норме' },
        ];
        this.sortedValue2 = this.$route.query.sort || '-date';
        this.slide = 'first';
        this.checkboxState = {};
    }
    get checkBoxValues() {
        this.checkboxState = { ...this.$store.state.analyzes.checkBoxValues };
        return this.$store.state.analyzes.checkBoxValues;
    }
    get checkedArr() {
        return this.$store.getters['analyzes/checkedArr'];
    }
    get analyzeRubrics() {
        const rubrics = [...this.$store.state.analyzes.analyzeRubricsList];
        const withSubrubrics = rubrics.filter((el) => el.subrubrics.length);
        const withoutSubrubrics = rubrics.filter((el) => !el.subrubrics.length);
        return [...withoutSubrubrics, ...withSubrubrics];
    }
    get getSelectedRubricIds() {
        return this.$store.getters['analyzes/getSelectedRubricIds'];
    }
    onCheckChange(key) {
        this.checkboxState[key] = !this.checkboxState[key];
        this.$store.commit('analyzes/setSelectedRubricIds', key);
        this.$store.commit('analyzes/setPropertyInStore', { name: 'checkBoxValues', value: this.checkboxState });
    }
    inputSelect(value) {
        this.sortedValue2 = value.value;
        if (window.screen.width >= 575) {
            this.$emit('sort', value.value);
        }
        // this.$emit('close-modal')
        // this.goTo('first');
    }
    clearGroup() {
        const items = this.checkBoxValues;
        for (const key in items) {
            items[key] = false;
        }
        this.$store.commit('analyzes/setPropertyInStore', { name: 'checkBoxValues', value: items });
        this.sortedValue = null;
        this.$emit('close-modal');
    }
    goTo(slide) {
        this.$refs.slider.goTo(slide);
    }
    goBack() {
        this.$refs.slider.goTo('first');
    }
    resetFilters() {
        this.clearGroup();
        this.sortedValue = null;
    }
    putChange() {
        this.$refs.modal.hide();
        this.$emit('sort', this.sortedValue2);
        bus.$emit(IAnalyzes.BusEvents.SET_CATEGORY, Boolean(this.checkedArr));
    }
    get disableClearFilterBtn() {
        return !(this.sortedValue || this.checkedArr);
    }
    closeModal() {
        return false;
    }
};
__decorate([
    Prop({ required: true })
], AnalyzesFilter.prototype, "isShow", void 0);
__decorate([
    Emit('setFilter')
], AnalyzesFilter.prototype, "putChange", null);
__decorate([
    Emit('close-modal')
], AnalyzesFilter.prototype, "closeModal", null);
AnalyzesFilter = __decorate([
    Component({
        components: {
            MainSelect,
            MainBtn,
            CloseBtn,
            InputDate,
            CheckboxInput,
            BackBtn,
        },
    })
], AnalyzesFilter);
export default AnalyzesFilter;
