import { __decorate } from "tslib";
import { Component, Vue, Emit } from 'vue-property-decorator';
import MainSelect from '@/components/UI/MainSelect.vue';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
let AnalyzesSelect = class AnalyzesSelect extends Vue {
    constructor() {
        super(...arguments);
        this.checkboxState = {};
        this.isHide = false;
        this.filteredRubrics = [];
    }
    test() {
        console.log(1);
    }
    async mounted() { }
    get checkboxValues() {
        this.checkboxState = { ...this.$store.state.analyzes.checkBoxValues };
        return this.$store.state.analyzes.checkBoxValues;
    }
    get checkedArr() {
        return this.$store.getters['analyzes/checkedArr'];
    }
    get analyzeRubrics() {
        const subrubrics = [...this.$store.state.analyzes.analyzeRubricsList];
        const sortedRubrics = {};
        const subrubricsWithoutRubric = [];
        subrubrics.forEach(subrubric => {
            const parent = subrubric.parent_rubric_name;
            if (parent === null) {
                subrubricsWithoutRubric.push(subrubric);
            }
            else {
                sortedRubrics[parent] ? sortedRubrics[parent].push(subrubric) : sortedRubrics[parent] = [subrubric];
            }
        });
        return { ...sortedRubrics, 'Без группы': subrubricsWithoutRubric };
    }
    get getSelectedRubricIds() {
        return this.$store.getters['analyzes/getSelectedRubricIds'];
    }
    onCheckChange(key) {
        this.checkboxState[key] = !this.checkboxState[key];
        this.$store.commit('analyzes/setSelectedRubricIds', key);
        this.$store.commit('analyzes/setPropertyInStore', { name: 'checkBoxValues', value: this.checkboxState });
    }
    onSelect() {
        this.isHide = false;
        return this.checkboxState;
    }
    removeSingleSubrubric(subrubricId) {
        this.onCheckChange(subrubricId);
        setTimeout(() => {
            this.onSelect();
        }, 300);
    }
};
__decorate([
    Emit('select')
], AnalyzesSelect.prototype, "onSelect", null);
AnalyzesSelect = __decorate([
    Component({
        components: {
            MainSelect,
            CheckboxInput,
            MainBtn,
        },
    })
], AnalyzesSelect);
export default AnalyzesSelect;
