<template lang="pug">
.cat-item
  .cat-item__title {{cat.name}}
  .cat-item__group
  img.cat-item__image(:src="cat.icon")
</template>

<script>
export default {
  props: ['cat']
}
</script>