import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import { IRouter } from '@/interfaces/router.interface';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import AnalyzesFilter from '@/components/modals/AnalyzesFilter.vue';
import AnalyzesByCategoryActions from '@/views/AnalyzesPage/AnalyzesByCategoryActions.vue';
import AnalyzesBaCategoryCard from '@/components/analyzes/AnalyzesBaCategoryCard.vue';
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import { pluralizeText, paginationRequest } from '@/utils/utils';
import { getResults, getRubrics } from '@/api/analyzes.js';
import InfiniteLoading from 'vue-infinite-loading';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import SearchAnalyzes from './SearchAnalyzes.vue';
import FailedSearch from './FailedSearch.vue';
let TabAll = class TabAll extends Vue {
    constructor() {
        super(...arguments);
        this.printableData = [];
        this.isGrouped = false;
        this.filteredResults = [];
        this.groupedResults = {};
        this.showFilter = false;
        this.analyzeResults = [];
        this.allCount = null;
        this.initialAllCount = null;
        this.analyzeRubrics = [];
        this.loading = false;
        this.sortType = this.$route.query.sort || '-date';
        this.rubricsResults = [];
        this.checkedSubrubrics = '';
        this.searchQuery = '';
        this.mobileSearchMode = false;
    }
    convertShitObject(shitObject) {
        // console.log(shitObject)
        const checkedSubrubricsArray = [];
        for (const key in shitObject) {
            const prop = shitObject[key];
            if (prop) {
                checkedSubrubricsArray?.push(key);
            }
        }
        const checkedSubrubricsString = checkedSubrubricsArray.join(',');
        return checkedSubrubricsString;
    }
    get flatSubrubricsResults() {
        // const allSubrubrics = []
        // this.rubricsResults.forEach(rubric => {
        //   const notEmptySubrubrics = rubric.subrubrics.filter(subrubric => subrubric.results.length > 0)
        //   notEmptySubrubrics.forEach(subrubric => subrubric.parent = rubric.name);
        //   allSubrubrics.push(...notEmptySubrubrics)
        // })
        // return allSubrubrics
        if (!this.checkedSubrubrics)
            return null;
        const checkedArr = this.checkedSubrubrics.split(',');
        const checkedObj = {};
        checkedArr.forEach(rubricId => {
            checkedObj[rubricId] = [];
        });
        console.log('rubricsResults', this.rubricsResults);
        this.rubricsResults.forEach(result => {
            result.rubrics.forEach(resultRubricId => {
                checkedObj[resultRubricId]?.push(result);
            });
        });
        console.log('checkedObj', checkedObj);
        return checkedObj;
    }
    async setFilterNew(checkedSubrubricsShitObject) {
        this.showFilter = false;
        if (this.loading)
            return;
        const checkedSubrubricsString = this.convertShitObject(checkedSubrubricsShitObject);
        this.checkedSubrubrics = checkedSubrubricsString;
        this.loading = true;
        if (checkedSubrubricsString) {
            // this.rubricsResults = await getRubricsResults({subrubrics: checkedSubrubricsString})
            const rubricsResults = await getResults({ rubrics: checkedSubrubricsString, offset: 0, count: 20, sort_type: this.sortType });
            console.log(rubricsResults);
            this.rubricsResults = rubricsResults.results;
            this.allCount = rubricsResults.all_count;
        }
        else {
            this.rubricsResults = [];
            this.allCount = this.initialAllCount;
            // const analyzeResults = await getResults({offset: 0, count: 20, sort_type: this.sortType});
            // this.analyzeResults = analyzeResults.results;
        }
        this.loading = false;
        console.log('checkedSubrubricsString', checkedSubrubricsString, this.rubricsResults);
    }
    async mounted() {
        // TODO вынести analyzeResults на уровень страницы, передавать по пропсам
        this.loading = true;
        const [analyzeResults, analyzeRubrics] = await Promise.all([
            getResults({ offset: 0, count: 20, sort_type: this.sortType }),
            getRubrics()
        ]);
        this.analyzeResults = analyzeResults.results;
        this.allCount = analyzeResults.all_count;
        this.initialAllCount = analyzeResults.all_count;
        this.analyzeRubrics = analyzeRubrics;
        // TODO выпилить это дерьмо
        this.filteredResults = [...this.analyzeResults]; //???
        bus.$on(IAnalyzes.BusEvents.SET_CATEGORY, (status) => (this.isGrouped = status));
        bus.$on(IAnalyzes.BusEvents.SHOW_FILTER, (payload) => {
            this.showFilter = payload;
        });
        this.loading = false;
    }
    async loadNextPage($state) {
        const isCategoryMode = this.checkedSubrubrics;
        const params = {
            offset: isCategoryMode ? this.rubricsResults.length : this.analyzeResults.length,
            count: 20,
            sort_type: this.sortType,
            rubrics: isCategoryMode ? this.checkedSubrubrics : null,
            query: this.searchQuery || null
        };
        const res = await paginationRequest({
            request: getResults,
            params,
            targetArray: isCategoryMode ? this.rubricsResults : this.analyzeResults,
            $state,
            nested: 'results'
        });
        console.log(res);
        this.allCount = res.all_count;
        return res;
    }
    async sortAnalyzes(sortType) {
        this.$router.push({ query: { sort: sortType } }).catch(() => { });
        this.sortType = sortType;
        this.analyzeResults = [];
        this.loading = true;
        const res = await getResults({ offset: 0, count: 20, sort_type: sortType });
        this.analyzeResults = res.results;
        this.loading = false;
    }
    destroyed() {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'selectedRubricIds', value: [] });
        bus.$off(IAnalyzes.BusEvents.SET_CATEGORY);
        this.resetCompareMode();
    }
    // TODO Refactor
    get groupedResult2() {
        return Object.entries(this.groupedResults).filter((el) => el[1].length);
    }
    get compareMode() {
        return this.$store.state.analyzes.compareMode;
    }
    get biomarkerList() {
        return this.$store.state.analyzes.analyzeBiomarkerList?.biomarkers;
    }
    // get analyzeResults(): IAnalyzes.IAnalyzeResult[] {
    //   return this.$store.state.analyzes.analyzeResultsList;
    // }
    // get analyzeRubrics(): IAnalyzes.IAnalyzeRubric[] | any {
    //   return this.$store.state.analyzes.analyzeRubricsList;
    // }
    get getSelectedRubricIds() {
        return this.$store.getters['analyzes/getSelectedRubricIds'];
    }
    get isCompareMode() {
        return this.$store.state.analyzes.compareMode;
    }
    get comparingItems() {
        return this.$store.state.analyzes.comparingItems;
    }
    get biomarkerQuantity() {
        const quantity = this.allCount;
        if (quantity) {
            const ru = ['биомаркер', 'биомаркера', 'биомаркеров'];
            return `${quantity} ${pluralizeText(quantity, ru)}`;
        }
        else {
            return this.loading ? 'Загрузка ...' : '0';
        }
    }
    setFilters() {
        this.filteredResults = [];
        const results = [...this.analyzeResults];
        const rubricIds = [...this.getSelectedRubricIds];
        const rubrics = [...this.analyzeRubrics];
        const result = {};
        const selectedRubrics = [];
        for (const id of rubricIds) {
            results.forEach((result) => {
                if (result.rubrics.includes(id)) {
                    this.filteredResults?.push(result);
                }
            });
            rubrics.forEach((rubric) => {
                if (rubric.id === id) {
                    selectedRubrics?.push(rubric);
                }
                if (rubric.subrubrics.length) {
                    rubric.subrubrics.forEach((subrubric) => {
                        if (subrubric.id === id) {
                            selectedRubrics?.push(subrubric);
                        }
                    });
                }
            });
            const prop = selectedRubrics.find((el) => el.id === id);
            if (typeof prop !== 'undefined') {
                result[prop.name] = [];
                for (const res of this.filteredResults) {
                    if (res.rubrics.includes(id)) {
                        result[prop.name]?.push(res);
                    }
                }
            }
        }
        this.groupedResults = { ...result };
    }
    goToDynamicAnalyzesPage() {
        const ids = this.comparingItems.join();
        this.$router.push({ name: IRouter.ROUTE_NAME.DYNAMICS_ANALYZES, query: { ids } });
    }
    resetCompareMode() {
        this.$store.commit('analyzes/setPropertyInStore', { name: 'compareMode', value: false });
        this.$store.commit('analyzes/clearComparingItems');
    }
    showFilterClose() {
        this.showFilter = false;
    }
    rubricName(analyzeResult, name) {
        return this.analyzeRubrics.find((el) => analyzeResult[0].includes(el.name))[name];
    }
    rubricNameById(id) {
        return this.analyzeRubrics.find(item => item.id == id).name;
    }
    rubricParentById(id) {
        return this.analyzeRubrics.find(item => item.id == id).parent_rubric_name;
    }
    async getAnalyzesByQuery() {
        console.log(this.searchQuery);
        this.analyzeResults = [];
        this.loading = true;
        this.allCount = null;
        await this.loadNextPage();
        this.loading = false;
    }
    get isSearchFailed() {
        const { searchQuery, analyzeResults, loading } = this;
        return !loading && searchQuery && analyzeResults.length === 0;
    }
};
__decorate([
    Watch('searchQuery')
], TabAll.prototype, "getAnalyzesByQuery", null);
TabAll = __decorate([
    Component({
        components: {
            AnalyzesByCategoryActions,
            AnalyzesBaCategoryCard,
            MainBtn,
            AnalyzesFilter,
            AnalyzeItem,
            InfiniteLoading,
            LoadingSpinner,
            SearchAnalyzes,
            FailedSearch
        },
    })
], TabAll);
export default TabAll;
