import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
let SortSelect = class SortSelect extends Vue {
    constructor() {
        super(...arguments);
        this.isIconReverse = false;
        this.qMenuWidth = 0;
    }
    inputSelect(value) {
        return value;
    }
    popupShow() {
        this.isIconReverse = true;
    }
    popupHide() {
        this.isIconReverse = false;
    }
    mounted() {
        this.qMenuWidth = this.$refs.select.$el?.offsetWidth;
    }
};
__decorate([
    Prop({ required: true })
], SortSelect.prototype, "value", void 0);
__decorate([
    Prop({ required: true })
], SortSelect.prototype, "options", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "optionValue", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "optionLabel", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "multiple", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "labelTitle", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "selectLabel", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "disabled", void 0);
__decorate([
    Prop({ default: 'transparent' })
], SortSelect.prototype, "bcgColor", void 0);
__decorate([
    Prop({ default: 'none' })
], SortSelect.prototype, "borderColor", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "width", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "height", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "minWidth", void 0);
__decorate([
    Prop()
], SortSelect.prototype, "maxWidth", void 0);
__decorate([
    Prop({ default: '' })
], SortSelect.prototype, "optionsLabel", void 0);
__decorate([
    Emit('input-select')
], SortSelect.prototype, "inputSelect", null);
SortSelect = __decorate([
    Component({
        components: { CheckboxInput },
    })
], SortSelect);
export default SortSelect;
