import { __decorate } from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import AnalyzesMixin from '@/mixins/analyzes-mixin';
import CheckboxInput from '@/components/UI/inputs/CheckboxInput.vue';
import { countColor } from '@/utils/utils';
let AnalyzesBaCategoryCard = class AnalyzesBaCategoryCard extends AnalyzesMixin {
    constructor() {
        super(...arguments);
        this.showTooltip = false;
    }
    get isCompareMode() {
        return this.$store.state.analyzes.compareMode;
    }
    get comparingItems() {
        return this.$store.state.analyzes.comparingItems;
    }
    get countColor() {
        return countColor;
    }
    get isChecked() {
        return this.comparingItems.includes(this.data.biomarker_group_id);
    }
    compareChange(id) {
        this.$store.commit('analyzes/setComparingItems', id);
    }
    go(id) {
        this.$router.push({ path: `/analyzes/${id}` });
    }
    get resultDifference() {
        return (this.data.value - this.data.prev_result).toFixed(1);
    }
};
__decorate([
    Prop({ default: () => ({}) })
], AnalyzesBaCategoryCard.prototype, "data", void 0);
AnalyzesBaCategoryCard = __decorate([
    Component({
        components: {
            CheckboxInput,
        },
    })
], AnalyzesBaCategoryCard);
export default AnalyzesBaCategoryCard;
